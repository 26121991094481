<template xmlns="http://www.w3.org/1999/html">
  <div style="text-align: left">
    <ul>
      <li v-for="(item, ind) in audits" :key="item.id">
        <div style="margin: 5px 0;">
          <div>{{ item.auditUserName }}</div>
          <a-upload
            :action="uploadFileUrl"
            :fileList="item.fileList"
            @change="(fileInfo) => handleChange(fileInfo, ind)"
            @preview="showWpsRead"
          >
          </a-upload>
        </div>
      </li>
    </ul>

    <div v-if="uploadFile" style="display: flex; margin-top: 12px">
      <a-upload
        :action="uploadFileUrl"
        :fileList="fileList"
        @change="handleFileChange"
        @preview="showWpsRead"
        :remove="() => upLoadRemove"
        ><a-button
          v-if="
            infoData.processExecuteStatus &&
            infoData.taskKey == taskKey &&
            isCurrentUser
          "
          ><a-icon type="upload" />选择附件</a-button
        > </a-upload
      ><a-button
        style="margin-left: 12px"
        type="primary"
        v-if="
          infoData.processExecuteStatus &&
          infoData.taskKey == taskKey &&
          isCurrentUser
        "
        @click="uploadDoc"
        >{{ btnText }}</a-button
      >
    </div>

    <a-button
      v-show="false"
      type="link"
      v-if="
        infoData.processExecuteStatus &&
        infoData.taskKey == taskKey &&
        isCurrentUser
      "
      @click="showaudit"
    >
      流转
    </a-button>
    <a-modal
      title="流转到下一节点"
      v-model="auditModal.visible"
      width="40%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <auditone :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
    <auditone :info="auditModal.info" @callback="auditCallBack" />
  </div>
</template>
<script>
import commons from "@/utils/commons";
import request from "@/request";
import auditone from "./auditone";

export default {
  name: "auditdocshow",
  props: {
    infoData: {
      type: Object,
    },
    taskKey: {
      type: String,
    },
    showRole: {
      type: Boolean,
    },
    uploadFile: {
      type: Boolean,
    },
    btnText: {
      type: String,
      default: "提交",
    },
    zhcheck: {
      type: Boolean,
    },
  },
  data() {
    return {
      isCurrentUser: true,
      audits: [],
      auditModal: {
        info: null,
        visible: false,
      },
      fileList: [],
      uploadFileUrl: "",
      upLoadRemove: false,
    };
  },
  components: {
    auditone,
  },
  watch: {
    infoData() {
      this.setValue();
    },
  },
  mounted() {
    this.uploadFileUrl = request.getUploadUrl("document");
    this.setValue();
  },
  methods: {
    setValue() {
      if (this.infoData.auditRecords) {
        let _userName = localStorage.getItem(commons.User.userName);
        var f = this.infoData.auditRecords.filter(
          (a) => a.taskKey == this.taskKey
        );
        var fileList = this.infoData.annex
          ? JSON.parse(this.infoData.annex).annex
          : [];
        fileList.forEach((l, i) => {
          l.uid = i;
          l.name = l.filename;
          l.status = "done";
          l.url = l.filepath;
          l.response = {
            url: l.filepath,
            title: l.filename,
          };
        });
        this.upLoadRemove = true;
        if (f.length > 0) {
          f.forEach((i) => {
            i.time = this.$moment(i.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            );
            i.fileList = fileList.filter((l) => {
              return l.user == i.auditUser;
            });
          });
          var _f = f.filter((a) => a.auditUser == _userName);
          if (_f.length > 0) {
            this.isCurrentUser = false;
          }
          this.audits = f;
        }
      }
    },
    showaudit() {
      this.auditModal.info = this.infoData;
      if (this.zhcheck) {
        this.$refs.audit.onSubmit(1);
      } else {
        this.auditModal.visible = true;
      }
    },
    auditCallBack(d) {
      if (d != null) {
        d.processExecuteStatus = 0;
        this.infoData.auditRecords = [d];
        this.setValue();
        this.fileList = [];
      }
      this.auditModal.visible = false;
      this.$emit("callback", d != null);
    },
    showWpsRead(file) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        type.toLowerCase().indexOf("doc") <= -1 &&
        type.toLowerCase().indexOf("xls") <= -1 &&
        type.toLowerCase().indexOf("ppt") <= -1 &&
        type.toLowerCase().indexOf("pdf") <= -1
      ) {
        window.open(file.response.url, "_blank");
      } else {
        let fileId =
          "file" +
          file.url
            .substring(file.url.lastIndexOf("/") + 1, file.url.lastIndexOf("."))
            .replaceAll("-", "");
        let url = file.url.substring(file.url.indexOf("oabucket"));
        let type = file.url.substring(file.url.lastIndexOf(".") + 1);
        localStorage.removeItem("fileRrl");
        localStorage.setItem("fileRrl", url);
        localStorage.removeItem("fileName");
        localStorage.setItem("fileName", file.name);
        localStorage.removeItem("fileId");
        localStorage.setItem("fileId", fileId);
        localStorage.removeItem("fileTpye");
        localStorage.setItem("fileTpye", type);
        const jump = this.$router.resolve({
          name: "wpsRead",
          query: {
            //要传的参数
            fileName: file.name,
          },
        });

        window.open(jump.href, "_blank");
      }
    },
    handleFileChange(fileInfo) {
      this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "error") {
        this.$message.error("上传失败");
      }
      if (fileInfo.file.status == "done") {
        this.$message.success("上传成功");
      }
    },
    uploadDoc() {
      this.$emit("uploadDoc", this.fileList);
    },
    handleChange(fileInfo, i) {
      /* if (fileInfo.file.user == localStorage.getItem(commons.User.userName)) {
        this.$set(this.audits[i], "fileList", fileInfo.fileList);
      } */
    },
  },
};
</script>
